<template>
  <div class="frame card" v-resize="onResize" ref="frame">
    <div class="card-header">
      <v-clamp autoresize :max-lines="1">
        <slot name="title"></slot>
      </v-clamp>
      <div class="editor">
        <slot name="editor"></slot>
      </div>
    </div>
    
    <div class="content card-body">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VClamp from 'vue-clamp'
import VueResizeObserver from "vue-resize-observer";
Vue.use(VueResizeObserver);

export default {
  name: 'WidgetFrame',
  components: {
    VClamp
  },
  data() {
    return {
      orientation: null, // True for Portait, False for Landscape
    };
  },
  mounted() {
    // We need to trigger an initial resize event with the widget's dimensions
    // when it first appears. However, there is a race condition between Vue's
    // mounted event and the browser drawing it, so we repeatedly check until
    // it's available.
    const self = this;
    var checkHeight = function() {
      const height = self.$refs.frame.clientHeight;
      const width = self.$refs.frame.clientWidth;
      if (height == 0) {
        setTimeout(() => {
          checkHeight();
        }, 50);
      } else {
        self.onResize({width, height});        
      }
    }
    checkHeight();
    // Because there's no guarantee child components have finished drawing,
    // check again on next tick to ensure they are still notified
    // (this happens for us when a widget appears by reloading default)
    Vue.nextTick(function () {
      checkHeight();
    })
  },
  methods: {
    onResize({ width, height }) {
      // Pass down orientation change events
      const old = this.orientation;
      if (height > width) {
        this.orientation = true;
      } else {
        this.orientation = false;
      }
      // Emit an orientation change event if we have a listener for it
      if (this.$listeners && this.$listeners.onOrientationChange &&
          (old == null || old != this.orientation)) {
        var orientation = this.orientation;
        this.$emit('onOrientationChange', {orientation, width, height});
      } else {
        this.$emit('onResize', {width, height});
      }
    }
  }
}
</script>
<style scoped>
.frame {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 0;
}
.status-message {
  margin: auto;
  font-weight: 700;
  font-size: 16px;
}

.frame .card-header {
  color: var(--text-on-dark);
  background-color: var(--widget-frame-title-bg);
  font-size: 16px;
  padding: 0.50rem 1.25rem;
  font-weight: 600;
  height: 40px;
  min-height: 40px;
}
.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow: hidden;
}

.summary-text {
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.summary-text svg {
  font-size: 24px;
  margin-right: 10px;
}


.chart-holder {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
  padding-top: 5px;
}

.chart-holder > div {
  height: 99% !important;
}

.card-header > div {
  margin-right: 20px;
}
.editor {
  position: absolute;
  right: 0;
  top: 10px;
  font-size: 14px;
  visibility: hidden;
  position: absolute;
  cursor: pointer;
  transition: none;
}

.grid-stack-item:hover .editor {
  visibility: visible;
}
.editor.mobile {
  visibility: visible;
}

/** Grid styles */

.ag-grid {
  flex-grow: 1;
}
</style>

<style>
/** These styles aren't working in scoped css, so prefix with .frame */

.frame .ag-cell a:hover {
  text-decoration: underline;
}
</style>