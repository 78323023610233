var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.onResize,
          expression: "onResize",
        },
      ],
      ref: "frame",
      staticClass: "frame card",
    },
    [
      _c(
        "div",
        { staticClass: "card-header" },
        [
          _c(
            "v-clamp",
            { attrs: { autoresize: "", "max-lines": 1 } },
            [_vm._t("title")],
            2
          ),
          _c("div", { staticClass: "editor" }, [_vm._t("editor")], 2),
        ],
        1
      ),
      _c("div", { staticClass: "content card-body" }, [_vm._t("content")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }